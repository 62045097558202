const keyList = [
  'A',
  'A#',
  'Bb',
  'B',
  'C',
  'C#',
  'Db',
  'D',
  'D#',
  'Eb',
  'E',
  'F',
  'F#',
  'Gb',
  'G',
  'G#',
  'Ab',
  'Nashville',
  'Solfège',
];

const keyChords = {
  Ab: ['Ab', 'Bb', 'Cb', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G'],
  A: ['A', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'],
  'A#': ['A#', 'C', 'Db', 'D', 'D#', 'E', 'F', 'Gb', 'G', 'Ab', 'A'],
  Bb: ['Bb', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A'],
  B: ['B', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#'],
  Cb: ['Cb', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb'],
  C: ['C', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'],
  'C#': ['C#', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'Cb', 'C'],
  Db: ['Db', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb', 'C'],
  D: ['D', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B', 'C', 'C#'],
  'D#': ['D#', 'F', 'Gb', 'G', 'G#', 'A', 'A#', 'B', 'C', 'Db', 'D'],
  Eb: ['Eb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb', 'C', 'Db', 'D'],
  E: ['E', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#'],
  F: ['F', 'G', 'Ab', 'A', 'Bb', 'B', 'C', 'Db', 'D', 'Eb', 'E'],
  'F#': ['F#', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F'],
  Gb: ['Gb', 'Ab', 'A', 'Bb', 'Cb', 'C', 'Db', 'D', 'Eb', 'E', 'F'],
  G: ['G', 'A', 'Bb', 'B', 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#'],
  'G#': ['G#', 'A#', 'Cb', 'C', 'C#', 'D', 'D#', 'E', 'F', 'Gb', 'G'],
  Nashville: ['1', '2', '♭3', '3', '4', '#4', '5', '♭6', '6', '♭7', '7'],
  Solfège: ['Do', 'Re', 'Me', 'Mi', 'Fa', 'Fi', 'Sol', 'Le', 'La', 'Te', 'Ti'],
};

export { keyList, keyChords };
